import React from 'react'
import { BsCheck } from 'react-icons/bs';
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { customStyles } from '../../util/FormatUtil';

const CustomDate = (props) => {

    // console.log('props in CustomDate', props)

    let labelExt = props.fromFilterPage ? "Filter" : props.forInsurance ? "Insurance" : "";

    return (
        <>
            <div className="form-group row pr-1">
                <fieldset className='col-12 col-md-10 p-2'>
                    <legend aria-label={props.label + (props.fromFilterPage ? " Search" : "")} id={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')} style={{ fontSize: "0.8em" }} className={"pt-1 px-1 font-weight-bold"}>
                        {props.label}
                    </legend>
                    <div className={props.fromFilterPage ? "col-8 col-md-7 pl-md-0 pl-lg-1" : "col-12 col-md-8 pl-md-0 pr-md-1"}>
                        <DatePicker
                            id={props.label}
                            disabled={props.disabled ? props.disabled : false}
                            readOnly={props.readOnly ? props.readOnly : false}
                            minDate={new Date("01-01-1000")}
                            maxDate={new Date("12-31-9999")}
                            showTimeSelect={props.hasTime || props.timeOnly ? true : false}
                            showTimeSelectOnly={props.timeOnly ? true : false}
                            dateFormat={props.timeOnly ? "h:mm aa" : props.hasTime ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy"}
                            placeholderText={props.timeOnly ? "--:-- --" : props.hasTime ? "--/--/---- --:-- --" : "--/--/----"}
                            selected={props.value && props.hasTime ? Date.parse(
                                moment(props.value, "MM-DD-YYYY h:mm aa").toISOString()
                            ) : props.value && props.timeOnly ? Date.parse(moment(props.value, "h:mm aa").toISOString()) : props.value ? Date.parse(moment(props.value, "MM-DD-YYYY").toISOString()) : null}
                            onChange={(e) => props.handleDateOnChange(e)}
                            ariaLabelledBy={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')}
                        />
                    </div>
                    <div className={props.fromFilterPage ? "col-2 col-md-1" : "d-none"}>
                        {props.value || props.nullValue ? (<BsCheck className={"float-right"} color={"#21212E"} size={20} />) : null}
                    </div>
                    <div className={props.fromFilterPage ? "col-12 pl-0 pr-1" : "d-none"}>
                        <div className="row pt-1">
                            <div className="col-12">
                                <Select
                                    styles={customStyles}
                                    isMulti={true}
                                    isSearchable={true}
                                    aria-label={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')}
                                    placeholder={<div className="accessibilityText">Select...</div>}
                                    onChange={(e) => {
                                        props.handleChange(e)
                                    }}
                                    className={"state_select"}
                                    options={props.options}
                                    value={props.nullValue}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            {/* <div className={props.showDateWarning ? "form-group row" : "d-none"}>
                <div className="d-none d-md-inline col-md-4"></div>
                <div className="col-12 col-md-8 pr-1 pl-md-0 mb-2 m-0 text-danger verlag-bold" style={{ fontSize: "1rem" }} >
                *please include '/' when entering date
            </div>
        </div> */}
        </>
    )
}

export default CustomDate