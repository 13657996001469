import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import {CustomFieldType} from "../types/CustomFieldType";

export default class CustomFieldsAPI {

    public static async getAllCustomFields():Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makeGet("/api/admin/customFields");
    }

    public static async filterCustomFields(filter:{filter:{DisplayName?,FieldTypeID?,ValidPages?,IsRequired?, IsPHI?, IsActive?, Patient?}}):Promise<{success:boolean, reason:string, data?:CustomFieldType[]}>{
        return NetworkUtil.makePost("/api/admin/customFields/filter", filter)
    }

    public static async createCustomField(customField:CustomFieldType, fromPatient=false):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/customFields/create", {customField: customField, fromPatient: fromPatient})
    }

    public static async editCustomField(customField):Promise<ServerResponse>{
        return NetworkUtil.makePost(`/api/admin/customFields/${customField.IsPatientField ? 'editPatientField' :  'edit'}`, customField)
    }

    public static async updateSortOrder(customFields):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/customFields/updateSortOrder", customFields)
    }



    

}