import {sweetalert} from "../App";

export type ValidationFunction<T> = (key:keyof T, attribute:T[keyof T], obj:T) => ValidationResponse;

export interface ValidationResponse{
    success:boolean;
    error?:string;
}
export default class Validator<T>{

    validatorFunctions = new Map<keyof T, AbstractValidationEntry<T>>();

    withSimpleValidation(key: keyof T, isValid:ValidationFunction<T>){
        return this.withValidation(key, new ValidationEntry<T>(isValid));
    }

    withValidation(key: keyof T, validationEntry: AbstractValidationEntry<T>){
        this.validatorFunctions.set(key, validationEntry);
        return this;
    }

    withComposedValidation(key:keyof T, ...validations:AbstractValidationEntry<T>[]):Validator<T>{
        return this.withValidation(key, new ComposedValidationEntry<T>(...validations));
    }

    public validate(obj:T):ValidationResponse{
        let silentResponse = this.validateSilently(obj);
        if(!silentResponse.success){
            sweetalert.fire({
                title: "",
                text: silentResponse.error,
                icon: "error",
                confirmButtonText: "Ok"
            });
        }
        return silentResponse;
    }

    public validateField(obj:T):ValidationResponse{
        let silentResponse = this.validateSilently(obj);
        return silentResponse;
    }

    public validateSilently(obj:T):ValidationResponse{
        for(const key of this.validatorFunctions.keys()){
            let validationEntry = this.validatorFunctions.get(key);
            let response = validationEntry.isValid(key, obj);
            if(!response.success){
                return response;
            }
        }
        return {success:true, error:""};
    }

}


abstract class AbstractValidationEntry<T>{
    abstract isValid(key:keyof T, obj:T):ValidationResponse;
}

export class ValidationEntry<T> extends AbstractValidationEntry<T>{
    private validFunc:ValidationFunction<T>;

    constructor(isValid:ValidationFunction<T>){
        super();
        this.validFunc = isValid;
    }

    isValid(key:keyof T, obj:T):ValidationResponse{
        return this.validFunc(key, obj[key], obj);
    }
}

export class ComposedValidationEntry<T> extends AbstractValidationEntry<T>{
    withSimpleValidation(arg0: string, arg1: ValidationFunction<any>) {
        throw new Error("Method not implemented.");
    }
    validationEntries:AbstractValidationEntry<T>[];

    constructor(...validations:AbstractValidationEntry<T>[]){
        super();
        this.validationEntries = validations;
    }

    isValid(key: keyof T, obj: T): ValidationResponse{
        for(const validationEntry of this.validationEntries){
            let response = validationEntry.isValid(key, obj);
            if(!response.success){
                return response;
            }
        }
        return {success:true, error:""};
    }
}
