import React, { useEffect, useState } from "react";
import { auth } from "../../FirebaseConfig";
import AdminAPI from "../../network/AdminAPI";
import SystemAPI from "../../network/SystemAPI";
import InBetweenOverlay from "../InBetweenOverlay";
export default function LoggedOut() {
    // const auth = getAuth(app)

    const [logoURL, setLogoURL] = useState('')
    const [productName, setProductName] = useState('')
    const [showLoading, setShowLoading] = useState(false)
    const [buttonColorHexValue, setButtonColorHexValue] = useState('')

    useEffect(() => {
        document.title = 'Logged Out | Oklahoma Department of Health';
        document.body.style.backgroundColor = 'black';
        setShowLoading(true)
        auth.signOut().then(() => {
            let currentURL = window.location.href;

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";

                setLogoURL(data.ProductLogoURL);
                setProductName(data.Name)
                setButtonColorHexValue(data.ProductButtonColorHexValue)
                setShowLoading(false)
            })
        })
            .catch(e => {
                console.error(' an error occurred on logout', e)
            })


    }, [])

    return (
        <React.Fragment>
            <InBetweenOverlay showInBetween={true} zIndex={-10} />
            <div className={showLoading ? "d-none" : "container min-vh-100"}>
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card shadow">
                            <div className="card-title text-center border-bottom">
                                <a href="/">
                                    <img src={logoURL}
                                        style={{ width: '200px', margin: '20px' }}
                                        className={'nav-logo'} alt={"Home | Oklahoma State Department of Health Immunization Service"}
                                    />
                                </a>
                            </div>
                            <div className="card-body">
                                <div className="text-center">Logged out successfully</div>
                                {/* <p className="text-center mt-3">To log in to the <b>{customerName} Portal</b> again, press the button below.</p> */}
                                <div className={"row mt-4"}>
                                    <div className={"col-12"}>
                                        <a href={"/login"} role="button" className={"btn btn-lg text-light w-100"} style={{ backgroundColor: buttonColorHexValue }}>
                                            Log In
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

