import React from "react";
import { hideModal } from "../../util/FormatUtil";


interface HintProps{
    header: string
    content: any
    zIndex?
    handleInBetweenOverlay?
    label?
}

interface HintState{

}
export class HintModal extends React.Component<HintProps, HintState>{
    public static ID = "hint";
    constructor(props) {
        super(props);
        this.state ={
        }
    }


    render() {
            return  (
                <React.Fragment>
                    <div className="modal fade form_modal" id={HintModal.ID} tabIndex={-1} style={{zIndex: this.props.zIndex}} role="dialog"
                        aria-label={this.props.label ? this.props.label : "Hint"}
                        aria-hidden="true" >
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 py-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <h4>{this.props.header}
                                                            <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                    aria-label="Close" onClick={() => {
                                                                        hideModal(HintModal.ID)
                                                                        this.props.handleInBetweenOverlay()
                                                                    }}>
                                                                <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                            </button>
                                                        </h4>
                                                    </div>
                                                    <div className="card-body">
                                                    {this.props.content.map((con) => (con))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

}
