import React from "react";


interface pageTool {
    showBtn: Boolean
}

class PaginationTool extends React.Component<any, pageTool>{

    constructor(props) {
        super(props);
        this.state = {
            showBtn: false
        };
        this.goToTop = this.goToTop.bind(this)
    }

    componentDidMount(): void {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400 && window.innerWidth > 575) {
                this.setState({showBtn: true})
            } else if(window.scrollY > 2600 && window.innerWidth < 575){ //For Samples Module, to avoid button overlapping with 'Search' and 'Clear Filters' buttons
                this.setState({showBtn: true})
            }else {
                this.setState({showBtn: false})
            }
        });
    }

    goToTop(){
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            this.state.showBtn ?
            <div className="pageTool">
                    <button className="btn immySubmitButtonSolid" onClick={this.goToTop}>Scroll To Top</button>
            </div>
            : <div></div>
        )
    }
}
export default PaginationTool;