import React from "react";
import Select from "react-select";
import {sweetalert} from "../../App";
import {isEmptyObject} from "jquery";
import {buildRow, getReactSelectAriaLabel, getReactSelectAriaLabelMultiselect, getSelectLabels, getlabelsFromIDs} from "../../util/FormatUtil";
import {isBlank, isOnlyWhitespace, isValidEmail} from "../../util/ValidationUtil";
import PhoneInput from 'react-phone-number-input'

interface PermsState{
    selectedUser: {
        UUID?
        FirstName?
        LastName?
        DisplayName?
        Email?
        SelectedPerms
        SelectedFacilities
        Status: string
        Disable: boolean
        AuthFacilityGroup,
        PhoneNumber
        FacilityIDs
    },
    permGroups: { label, value }[],
    facilityGroups: { label, value }[]
}

interface PermsProps{
    selectedUser
    facilityGroups
    permissionGroups
    facilities
    facilitiesWithInfo
    onSubmit
}

export class UserManagementModal extends React.Component<PermsProps, PermsState>{
    public static readonly ID = "userManagement";

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: {
                UUID: -1,
                DisplayName: '',
                FirstName:'',
                LastName:'',
                Email:'',
                SelectedPerms:[],
                SelectedFacilities: [],
                Status: 'Enabled',
                Disable: false,
                AuthFacilityGroup:null,
                PhoneNumber: null,
                FacilityIDs: null
            },
            facilityGroups:[],
            permGroups:[],
        } as PermsState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(): void {
        // $(`#${UserManagementModal.ID}`).on('shown.bs.modal', function() {
        //     $(document).off('focusin.modal');
        // });
    }

    componentWillReceiveProps(nextProps: Readonly<PermsProps>, _nextContext: any) {

        if(nextProps.facilityGroups){
            this.setState({facilityGroups: nextProps.facilityGroups})
        }

        if(nextProps.permissionGroups){
            this.setState({permGroups: nextProps.permissionGroups})
        }

        if (!isEmptyObject(nextProps.selectedUser)) {
            let user = nextProps.selectedUser;

            if (user.UUID && user.UUID !== -1) {
                this.setState({
                    selectedUser: {
                        DisplayName: user.DisplayName,
                        FirstName: user.FirstName,
                        LastName: user.LastName,
                        Email: user.Email,
                        SelectedPerms: this.state.permGroups.filter(f =>
                            user.Permissions.find(selected=>selected.id === f.value)
                        ),
                        SelectedFacilities: user.FacilityIDs && user.FacilityIDs.length > 0 ? this.props.facilities.filter(f => 
                            JSON.parse(user.FacilityIDs).includes(f.value) // FacilityIDs comes in as "[378]" not [378]
                        ) : [],
                        Status: user.Status,
                        Disable: user.Status === 'DEACTIVATE',
                        UUID: user.UUID,
                        AuthFacilityGroup: this.state.facilityGroups.find(a =>
                            user &&
                            user.AuthFacilityGroup === a.value ? a : null
                        ),
                        PhoneNumber:user.PhoneNumber ? user.PhoneNumber : null,
                        FacilityIDs : user.FacilityIDs ?  user.FacilityIDs : null
                    }
                })
            } else {
                this.setState({
                    selectedUser: {
                        DisplayName: "",
                        FirstName: "",
                        LastName: "",
                        Email: "",
                        SelectedPerms: [],
                        SelectedFacilities: [],
                        Status: "Enabled",
                        Disable: false,
                        AuthFacilityGroup: null,
                        UUID: -1,  // -1 means no user, create one
                        PhoneNumber:null,
                        FacilityIDs: null
                    }
                });
            }
        }
    }

    public static display(){
        window["$"]("#" + UserManagementModal.ID).modal({
            backdrop: 'static', // prevent outside click
            keyboard: false // prevent esc close
        })
    }

    getRoleDesc(){
        let fac = (
          <div style={{ fontSize: "small" }}>
            <p>Facility permission groups only have access to users and facilities to which they are assigned.</p>
            <dl className={"row"}>
              <dt className={"col-md-3"}>OSDH-IMM Admin</dt>
              <dd className={"col-md-9"}>Access to Vaccine Exemptions, Public Users, Audit Logs, Facility, User, Communication, File, Banner, Password Requirements</dd>
            </dl>
            <dl className={"row"}>
              <dt className={"col-md-3"}>OSDH-IMM Read-Only</dt>
              <dd className={"col-md-9"}>Read-Only access to Vaccine Exemptions, Public Users</dd>
            </dl>
          </div>
        );
        // let global = (<div style={{fontSize: "small"}}>
        //     <p>Global permission groups have access to all patients and facilities, regardless of their assigned facility group. They have permission to download a CSV export from Samples page with Patient Identifiers, and can access Audit Logs.</p>
        //     <dl className={"row"}>
        //     <dt className={"col-md-3"}>OraPath Global Admin</dt>
        //         <dd className={"col-md-9"}>On OraPath Portals only, user can: Edit and read access to Testing Check-in, Submission Form, Rapid Test Management, Schedule Management and Stats, Send Alert, Samples, Facility Management, and User Management.</dd>
        //     </dl>
        // </div>);
        let isGlob = this.state.permGroups.find(f => f.value === 2); //used to be Okta Global Lab group

        return (
            <div className={"col-md-12"}>
                <h5>Permission Group Descriptions</h5>
                <div className={"col-md-12"}>
                    {fac}
                    {isGlob ? global : null}
                </div>
                <hr />
            </div>
        )
    }

    handleChange(event) {
        this.setState((prevState) => ({
            selectedUser:{
                ...prevState.selectedUser,
                Disable: event.target.checked
            }
        }))
    }

    getUserAccountStatusInput(){
        let userAccountIsActive = this.state.selectedUser.Status === 'Enabled' &&
            this.state.selectedUser.UUID !== -1;

        let showAccountStatusAndCheckbox = (
            <div className={"col-sm-8 p-0 m-0 input-group"}>
                <input type={"search"} className={"form-control"}
                    readOnly={true} id={"Status"} value={this.state.selectedUser.Status} />
                <div className={"input-group-append"}>
                    <span className={"input-group-text disable-check-label"}>
                        Disable Account
                        <input className={"input-group-addon disable-check-input"}
                            type={"checkbox"}
                            onChange={this.handleChange}
                            checked={this.state.selectedUser.Disable}
                            readOnly={this.state.selectedUser.UUID !== -1}
                        />
                    </span>
                </div>
            </div>
        );

        let showAccountStatus = (
            <div className={"col-sm-8 p-0 m-0"}>
                <input type={"search"} className={"form-control"}
                    readOnly={true} id={"Status"} value={this.state.selectedUser.Status} />
            </div>
        );

        return (
            <div className={"form-group row"}>
                <label htmlFor={"Status"} className={"col-sm-4 col-form-label"}>
                    Account Status
                </label>
                { userAccountIsActive ?
                    showAccountStatusAndCheckbox :
                    showAccountStatus
                }
            </div>
        )
    }

    getFacilitesIncludedInSelectedFacilityGroup(){
        let options = [];

        if(!this.state.selectedUser || !this.state.selectedUser.AuthFacilityGroup || isEmptyObject(this.state.selectedUser.AuthFacilityGroup)){
            return options;
        }

        this.props.facilitiesWithInfo.map(f => {
            if(f.FacilityGroup === this.state.selectedUser.AuthFacilityGroup.label){
                options.push({label: f.FacilityName, value: f.ID})
            }
        })

        return options;
    }


    render() {
        // console.log('UserMgmt modal state', this.state)
        return  <div className="modal fade form_modal" id={UserManagementModal.ID} tabIndex={-1} role="dialog"
                    aria-labelledby="result_modal_label"
                    aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document">
                <div className="modal-content">
                    <div className="container-fluid">
                    <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">User Management</h5>
                        <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal"
                                aria-label="Close" onClick={()=>
                                    this.setState({
                                        selectedUser:{
                                            FirstName: "",
                                            LastName: "",
                                            Email: "",
                                            SelectedPerms: [],
                                            SelectedFacilities: [],
                                            Status: "Enabled",
                                            Disable: false,
                                            AuthFacilityGroup: {label: "", value: -1},
                                            UUID: -1,  // -1 means no user, create one
                                            PhoneNumber: null,
                                            FacilityIDs: null
                                        }
                                    })
                        }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={"row"}>
                            {this.getRoleDesc()}
                        </div>

                        { buildRow("First Name",
                            <input className="form-control"
                                id="FirstName"
                                maxLength={50}
                                autoComplete="off"
                                type={"search"}
                                name={"First Name"}
                                aria-label="First Name"
                                onChange={(e) => this.setState((prevState) => ({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        FirstName: e.target.value
                                    }
                                })
                                )}
                                //    readOnly={this.state.selectedUser.UUID !== -1}
                                value={this.state.selectedUser.FirstName}
                            />
                        )}

                        {buildRow("Last Name",
                            <input className="form-control"
                                id="LastName"
                                maxLength={50}
                                autoComplete="off"
                                type={"search"}
                                name={"Last Name"}
                                aria-label="Last Name"
                                onChange={(e) => this.setState((prevState) => ({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        LastName: e.target.value
                                    }
                                })
                                )}
                                // readOnly={this.state.selectedUser.UUID !== -1}
                                value={this.state.selectedUser.LastName}
                            />
                        )}

                        {buildRow("Phone Number",
                            <PhoneInput
                                id={'Phone Number'}
                                placeholder="Enter phone number (10 digit)"
                                value={this.state.selectedUser.PhoneNumber}
                                aria-label="Phone Number"
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedUser:{ ...prevState.selectedUser, PhoneNumber: e ? e : null}
                                }))}
                                defaultCountry="US"
                            />
                        )}

                        {buildRow("Email/Username",
                            <input className="form-control"
                                id="Email/Username"
                                maxLength={100}
                                autoComplete="off"
                                type={"search"}
                                name={"Email"}
                                placeholder="name@example.com"
                                aria-label="Email or Username"
                                onChange={(e) => this.setState((prevState) => ({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        Email: e.target.value
                                    }
                                })
                                )}
                                readOnly={this.state.selectedUser.UUID !== -1}
                                value={this.state.selectedUser.Email}
                            />
                        )}

                        { buildRow("Permission Groups",
                            <Select
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                value={this.state.selectedUser.SelectedPerms}
                                name={"Permissions"}
                                aria-label={getReactSelectAriaLabelMultiselect("Permission Groups", this.state.selectedUser.SelectedPerms)}
                                onChange={(e) => this.setState((prevState) => ({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        SelectedPerms: e
                                    }})
                                )}
                                className={"state_select"}
                                options={this.state.permGroups}
                                isMulti={true}
                            />
                        )}

                        { buildRow("Facility Group",
                            <Select
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                value={this.state.selectedUser.AuthFacilityGroup}
                                name={"Facility Group"}
                                aria-label={getReactSelectAriaLabel("Facility Group", this.state.selectedUser.AuthFacilityGroup)}
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        AuthFacilityGroup: e
                                    }})
                                )}
                                options={this.state.facilityGroups}
                                className={"state_select"}
                                isMulti={false}
                                readOnly={this.state.selectedUser.UUID !== -1}
                                isDisabled={this.state.selectedUser.UUID !== -1}
                            />
                        )}

                        { buildRow("Facilities",
                            <Select
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                value={this.state.selectedUser.SelectedFacilities}
                                name={"Facilities"}
                                aria-label={getReactSelectAriaLabelMultiselect("Facilities", this.state.selectedUser.SelectedFacilities)}
                                onChange={(e) => this.setState((prevState)=>({ 
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        SelectedFacilities: e
                                    }})
                                )}
                                options={this.getFacilitesIncludedInSelectedFacilityGroup()}
                                className={"state_select"}
                                isMulti={true}
                                readOnly={this.state.selectedUser.UUID !== -1}
                                isDisabled={this.state.selectedUser.UUID !== -1}
                            />
                        )}

                        { this.getUserAccountStatusInput() }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary"
                                    onClick={()=>{
                                        //form validations
                                        if(isBlank(this.state.selectedUser.FirstName) ||
                                            isOnlyWhitespace(this.state.selectedUser.FirstName)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'First Name is required.'})
                                        }
                                        if(isBlank(this.state.selectedUser.LastName) ||
                                            isOnlyWhitespace(this.state.selectedUser.LastName)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Last Name is required.'})
                                        }
                                        if(isBlank(this.state.selectedUser.Email) ||
                                            isOnlyWhitespace(this.state.selectedUser.Email)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Email is required.'})
                                        }
                                        if(!isValidEmail(this.state.selectedUser.Email)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Email format is invalid.'})
                                        }
                                        if(!this.state.selectedUser.SelectedPerms ||
                                            this.state.selectedUser.SelectedPerms.length === 0){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'At least one Permission Group is required.'})
                                        }
                                        if(!this.state.selectedUser.SelectedFacilities ||
                                            this.state.selectedUser.SelectedFacilities.length === 0){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'At least one Facility is required.'})
                                        }
                                        if(this.state.selectedUser.AuthFacilityGroup.value === -1){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Facility Group is required. Select "null" to leave Facility Group unassigned'})
                                        }
                                        this.props.onSubmit(this.state.selectedUser)
                                    }}
                            >
                                Submit
                            </button>
                        </div>
                    
                </div>
                </div>

            </div>
        </div>;
    }
}